import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'

import Logo from '../Logo'
import Button from '../Button'

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? { className: 'fb-navigation__item fb-navigation__item--active' }
    : { className: 'fb-navigation__item' }
}

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: 'fb-navigation__item fb-navigation__item--active' }
    : { className: 'fb-navigation__item' }
}

class Navigation extends Component {
  state = {
    isExpanded: false
  }

  toggleExpand = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })

    // We want to add an overlay on the rest of the page
    document.getElementsByClassName('fb-page')[0].classList.toggle('fb-u-overlay--small-screen')
  }

  closeMenu = () => {
    document.getElementsByClassName('fb-page')[0].classList.remove('fb-u-overlay--small-screen')

    this.setState({
      isExpanded: false
    })
  }

  render () {
    const {
      site,
      items
    } = this.props

    const {
      isExpanded
    } = this.state

    return (
      <div
        className={classnames(
          'fb-navigation',
          {'fb-navigation--expanded': isExpanded}
        )}
      >
        <div className='fb-navigation__content'>
          <a href='/' className='fb-navigation__logo' key='homelink'>
            <Logo siteCode={site} />
          </a>
          {items.map(({
            button,
            external,
            title,
            path,
            active,
            exact,
            ...otherProps
          }, index) => {
            if (active || !path) {
              return (
                <span
                  key={`navlink${index}`}
                  className='fb-navigation__item fb-navigation__item--active'
                  onClick={this.closeMenu}
                >
                  { title }
                </span>
              )
            }

            if (button) {
              return (
                <Button
                  key={`navlink${index}`}
                  cta
                  size='small'
                  color='accent'
                  href={path} className='fb-navigation__button'
                >
                  {title}
                </Button>
              )
            }

            if (external) {
              return (
                <a
                  key={`navlink${index}`}
                  href={path}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='fb-navigation__item'
                  onClick={this.closeMenu}
                  {...otherProps}
                >
                  {title}
                </a>
              )
            }

            return (
              <Link
                key={`navlink${index}`}
                to={path}
                getProps={exact ? isActive : isPartiallyActive}
                onClick={this.closeMenu}
                {...otherProps}
              >
                {title}
              </Link>
            )
          })}
          <button className='fb-navigation__toggle' onClick={this.toggleExpand}>
            More
            <span className='fb-navigation__toggle-more' />
          </button>
        </div>
      </div>
    )
  }
}

Navigation.propTypes = {
  /**
   * To inform on the context for the sub navigation
   */
  site: PropTypes.string,

  /**
   * Items in the menu
   */
  items: PropTypes.array.isRequired
}

Navigation.defaultProps = {
  site: 'fi'
}

export default Navigation
