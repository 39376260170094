import React, { Component } from 'react'

class TextureHeaderChar extends Component {
  componentDidMount () {
    var image = this.imageElement
    var imageSource = image.src

    if (image.complete) {
      image.src = imageSource
    }
  }

  render () {
    const {
      char,
      ...imageProps
    } = this.props
    return (
      <img
        ref={node => (this.imageElement = node)}
        alt={char}
        {...imageProps}
      />
    )
  }
}

export default TextureHeaderChar
