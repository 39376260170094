import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const PanelSection = ({
  children,
  spread,
  hasPadding
}) => (
  <div
    className={
      classnames(
        'fb-panel__content',
        { 'fb-panel__content--spread': spread },
        { 'fb-panel__content--with-padding': hasPadding }
      )
    }
  >
    {children}
  </div>
)

PanelSection.propTypes = {
  /**
   * The children of the component
   */
  children: PropTypes.node.isRequired,

  /**
   * Whether the component should expand to flex the full available height of
   * the Panel
   */
  spread: PropTypes.bool,

  /**
   * When nesting panel section inside full width panel sometimes we want it to keep padding
   */
  hasPadding: PropTypes.bool
}

PanelSection.defaultProps = {
  spread: false,
  hasPadding: false
}

PanelSection.displayName = 'Page.Panel.Section'

export default PanelSection
