import React from 'react'
import PropTypes from 'prop-types'

import FieldLogo from './logos/Field'
import FieldSupplyLogo from './logos/FieldSupply'
import ShelfLifeLogo from './logos/ShelfLife'
import ShelfLifeTaglineAccentLogo from './logos/ShelfLifeTaglineAccent'

const Logo = ({siteCode, ...otherProps}) => {
  const logos = {
    fi: <FieldLogo {...otherProps} />,
    fs: <FieldSupplyLogo {...otherProps} />,
    sl: <ShelfLifeLogo {...otherProps} />,
    sltag: <ShelfLifeTaglineAccentLogo {...otherProps} />
  }

  return logos[siteCode]
}

Logo.propTypes = {
  siteCode: PropTypes.string
}

Logo.defaultProps = {
  siteCode: 'fi'
}

export default Logo
