import React from 'react'

const Logo = ({className}) => {
  return (
    <svg viewBox='0 0 1007 160' className={className}>
      <title>Field Supply Logo</title>
      <defs>
        <polygon id='path-1' points='0.158 0.413 114.84 0.413 114.84 160 0.158 160' />
      </defs>
      <g stroke='none' strokeWidth='1' fillRule='evenodd'>
        <path d='M26.725,53.863 L26.725,27.138 L53.45,27.138 C53.45,12.378 41.485,0.413 26.725,0.413 C11.965,0.413 0,12.378 0,27.138 C0,41.898 11.965,53.863 26.725,53.863' id='Fill-1' />
        <path d='M101.856,53.863 C116.616,53.863 128.581,41.898 128.581,27.138 C128.581,12.378 116.616,0.413 101.856,0.413 C87.096,0.413 75.131,12.378 75.131,27.138 L101.856,27.138 L101.856,53.863' id='Fill-2' />
        <path d='M26.725,75.544 C11.965,75.544 0,87.51 0,102.269 C0,117.029 11.965,128.994 26.725,128.994 C41.485,128.994 53.45,117.029 53.45,102.269 L26.725,102.269 L26.725,75.544' id='Fill-3' />
        <path d='M101.856,75.544 L101.856,102.269 L75.131,102.269 C75.131,117.029 87.096,128.994 101.856,128.994 C116.616,128.994 128.581,117.029 128.581,102.269 C128.581,87.51 116.616,75.544 101.856,75.544' id='Fill-4' />
        <path d='M235.109,18.281 L246.146,18.281 L246.146,0.413 L235.109,0.413 L235.109,18.281 Z M246.146,38.427 L246.146,128.994 L235.286,128.994 L235.286,47.887 L192.892,47.887 L192.892,128.994 L182.031,128.994 L182.031,47.887 L165.915,47.887 L169.399,38.427 L182.031,38.427 L182.031,24.237 C182.031,8.121 190.79,0.413 206.031,0.413 C209.884,0.413 213.038,0.763 215.314,1.464 L215.314,10.924 C212.687,10.398 209.884,10.048 206.906,10.048 C196.571,10.048 192.892,15.128 192.892,25.814 L192.892,38.427 L246.146,38.427 Z' id='Fill-5' />
        <polygon id='Fill-6' points='359.021 128.994 369.881 128.994 369.881 0.413 359.021 0.413' />
        <path d='M456.178,90.806 L456.178,78.193 C456.178,59.974 443.039,47.711 424.12,47.711 C406.602,47.711 395.391,58.397 395.391,74.688 L395.391,94.309 C395.391,110.601 406.602,121.287 424.12,121.287 C443.039,121.287 456.178,109.024 456.178,90.806 Z M384.004,84.499 C384.004,55.069 398.018,38.252 422.018,38.252 C437.784,38.252 448.82,44.034 455.126,55.77 L456.178,55.77 L456.178,0.413 L467.214,0.413 L467.214,128.994 L457.053,128.994 L456.178,113.228 L455.126,113.228 C448.82,124.965 437.784,130.746 422.018,130.746 C398.018,130.746 384.004,113.929 384.004,84.499 Z' id='Fill-7' />
        <path d='M271.714,76.791 L330.398,76.791 L330.398,72.762 C330.398,56.821 319.012,46.134 302.02,46.134 C283.977,46.134 271.714,57.346 271.714,73.988 L271.714,76.791 Z M330.749,47.537 C337.931,54.719 341.435,64.704 341.435,77.142 C341.435,80.47 341.259,83.273 340.909,85.899 L271.714,85.899 L271.714,91.156 C271.714,109.374 283.801,121.287 302.545,121.287 C316.734,121.287 327.42,113.579 330.749,102.191 L341.785,102.191 C339.858,110.776 335.304,117.607 327.946,122.862 C320.764,128.118 312.005,130.745 302.02,130.745 C289.231,130.745 279.071,126.542 271.539,117.958 C264.006,109.374 260.327,97.812 260.327,83.273 C260.327,54.018 276.094,36.676 302.02,36.676 C314.107,36.676 323.566,40.354 330.749,47.537 Z' id='Fill-8' />
        <path d='M522.889,100.791 C522.889,110.776 530.597,117.083 543.736,117.083 C555.648,117.083 562.479,111.826 562.479,103.243 C562.479,95.535 557.399,92.908 550.917,91.681 L532.174,88.178 C515.357,85.2 506.948,76.792 506.948,63.303 C506.948,45.609 520.962,35.099 542.685,35.099 C564.932,35.099 578.246,46.486 578.771,63.477 L561.253,63.477 C560.903,53.667 554.597,48.763 542.509,48.763 C531.474,48.763 525.166,53.667 525.166,61.55 C525.166,68.207 529.896,72.237 539.18,73.463 L556.699,76.617 C572.991,79.595 581.223,88.178 581.223,102.543 C581.223,119.886 566.333,130.746 543.736,130.746 C519.736,130.746 505.197,118.834 505.197,100.791 L522.889,100.791' id='Fill-9' />
        <path d='M673.117,36.85 L673.117,128.994 L657.525,128.994 L655.072,112.003 L653.672,112.003 C648.241,123.739 637.204,130.746 622.666,130.746 C603.221,130.746 592.009,117.783 592.009,95.36 L592.009,36.85 L610.227,36.85 L610.227,92.732 C610.227,107.623 616.709,115.155 629.672,115.155 C645.089,115.155 655.072,102.893 655.072,85.2 L655.072,36.85 L673.117,36.85' id='Fill-10' />
        <g id='Group-14' transform='translate(892.000000, 0.000000)'>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1' />
          </mask>
          <g id='Clip-12' />
          <polygon id='Fill-11' mask='url(#mask-2)' points='0.158 128.994 18.026 128.994 18.026 0.413 0.158 0.413' />
          <path d='M29.526,36.85 L49.146,36.85 L63.86,79.068 L72.62,106.923 L73.671,106.923 L82.079,79.068 L95.743,36.85 L114.84,36.85 L81.904,130.571 C75.072,150.191 62.635,160.001 44.767,160.001 C41.963,160.001 38.985,159.651 35.832,159.125 L35.832,143.885 C38.635,144.411 41.088,144.586 43.189,144.586 C52.825,144.586 59.306,139.855 62.283,130.571 L64.211,125.666 L29.526,36.85' id='Fill-13' mask='url(#mask-2)' />
        </g>
        <path d='M759.332,91.156 L759.332,73.639 C759.332,59.449 750.223,50.164 734.281,50.164 C717.464,50.164 706.429,60.85 706.429,77.142 L706.429,87.652 C706.429,103.944 717.641,114.805 734.281,114.805 C749.697,114.805 759.332,105.871 759.332,91.156 Z M688.386,158.249 L688.386,36.85 L704.326,36.85 L706.429,53.142 L707.655,53.142 C713.436,41.756 725.349,35.099 740.063,35.099 C763.888,35.099 778.077,52.442 778.077,82.397 C778.077,112.527 763.888,129.695 740.063,129.695 C724.472,129.695 713.611,123.739 707.655,111.651 L706.429,111.651 L706.429,158.249 L688.386,158.249 Z' id='Fill-15' />
        <path d='M860.823,91.156 L860.823,73.639 C860.823,59.449 851.714,50.164 835.772,50.164 C818.955,50.164 807.92,60.85 807.92,77.142 L807.92,87.652 C807.92,103.944 819.131,114.805 835.772,114.805 C851.188,114.805 860.823,105.871 860.823,91.156 Z M789.877,158.249 L789.877,36.85 L805.817,36.85 L807.92,53.142 L809.146,53.142 C814.927,41.756 826.84,35.099 841.554,35.099 C865.379,35.099 879.568,52.442 879.568,82.397 C879.568,112.527 865.379,129.695 841.554,129.695 C825.963,129.695 815.103,123.739 809.146,111.651 L807.92,111.651 L807.92,158.249 L789.877,158.249 Z' id='Fill-16' />
      </g>
    </svg>
  )
}

export default Logo
