import { createElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Heading from './Heading'
import TextureHeading from './TextureHeading'

const Text = ({
  children,
  size,
  tight,
  weight,
  display,
  color,
  tag,
  className,
  ...otherProps
}) => {
  const classNames = classnames(
    'fb-text',
    {'fb-text--weight-regular': weight === 'regular'},
    {'fb-text--weight-bold': weight === 'bold'},
    {'fb-text--size-tiny': size === 'tiny'},
    {'fb-text--size-xsmall': size === 'xsmall'},
    {'fb-text--size-small': size === 'small'},
    {'fb-text--size-body': size === 'body'},
    {'fb-text--size-large': size === 'large'},
    {'fb-text--size-xlarge': size === 'xlarge'},
    {'fb-text--size-xxlarge': size === 'xxlarge'},
    {'fb-text--display-text': display === 'text'},
    {'fb-text--display-number': display === 'number'},
    {'fb-text--color-light': color === 'light'},
    {'fb-text--color-brand': color === 'brand'},
    {'fb-text--color-error': color === 'error'},
    {'fb-text--tight': tight},
    className
  )

  return createElement(
    tag,
    {
      className: classNames,
      ...otherProps
    },
    children
  )
}

Text.propTypes = {
  /**
   * The size you want the text to render at
   */
  size: PropTypes.oneOf(['inherit', 'tiny', 'xsmall', 'small', 'body', 'large', 'xlarge', 'xxlarge']),
  /**
   * Should this text render with a tight line-height?
   * This is recommended in narrow spaces, e.g. narrow columns.
   */
  tight: PropTypes.bool,
  /**
   * At what weight should the text render
   */
  weight: PropTypes.oneOf(['inherit', 'regular', 'bold']),
  /**
   * Display type. Number display is almost exclusively used for batch numbers.
   */
  display: PropTypes.oneOf(['inherit', 'text', 'number']),
  /**
   * Optionally set color, e.g. if text is used on a colored background
   */
  color: PropTypes.oneOf(['inherit', 'light', 'brand', 'error']),
  /**
   * Alternative HTML tag for the text element
   */
  tag: PropTypes.string
}

Text.defaultProps = {
  size: 'inherit',
  tight: false,
  weight: 'inherit',
  display: 'inherit',
  color: 'inherit',
  tag: 'div'
}

Text.Heading = Heading
Text.TextureHeading = TextureHeading

export default Text
