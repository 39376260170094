import React from 'react'

const Logo = ({className}) => (
  <svg viewBox='0 0 505 139' className={className}>
    <title>Field</title>
    <g id='Field' stroke='none'>
      <path d='M476.696,57.611 L476.696,79.645 C476.696,103.182 467.303,112.783 444.274,112.783 L414.182,112.783 L414.182,24.473 L444.274,24.473 C467,24.473 476.696,34.383 476.696,57.611 Z M444.813,2.789 L389.811,2.789 L389.811,134.467 L444.813,134.467 C483.631,134.467 502.5,112.959 502.5,68.718 C502.5,24.354 483.631,2.789 444.813,2.789 Z' />
      <polyline points='2.5 134.467 26.872 134.467 26.872 85.918 85.631 85.918 85.631 65.13 26.872 65.13 26.872 24.473 92.796 24.473 92.796 2.789 2.5 2.789 2.5 134.467' />
      <polyline points='93.351 136.501 117.689 136.501 156.044 2.789 131.706 2.789 93.351 136.501' />
      <polyline points='173.815 134.467 264.827 134.467 264.827 112.783 198.185 112.783 198.185 76.604 255.87 76.604 255.87 56.173 198.185 56.173 198.185 24.473 264.827 24.473 264.827 2.789 173.815 2.789 173.815 134.467' />
      <polyline points='313.373 2.789 289.002 2.789 289.002 134.467 370.341 134.467 370.341 112.424 313.373 112.424 313.373 2.789' />
    </g>
  </svg>
)

export default Logo
