import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Label from './PanelLabel'
import Section from './PanelSection'

const Panel = ({
  children,
  color,
  noPadding,
  noPaddingTop,
  spread,
  texture,
  className
}) => {
  const africanTextureTerritories = ['africa', 'kenya', 'nigeria']
  const showAfricanTexture = texture && africanTextureTerritories.indexOf(texture.toLowerCase()) > -1
  const classes = classnames(
    'fb-panel',
    { 'fb-panel--spread': spread },
    { 'fb-panel--no-padding': noPadding },
    { 'fb-panel--no-padding-top': noPaddingTop },
    { 'fb-panel--color-white': color && color.toLowerCase() === 'white' },
    { 'fb-panel--color-grey': color && color.toLowerCase() === 'grey' },
    { 'fb-panel--color-brand': color && color.toLowerCase() === 'brand' },
    { 'fb-panel--color-accent': color && color.toLowerCase() === 'accent' },
    { 'fb-panel--texture': texture },
    { 'fb-panel--texture-amoeba': texture && texture.toLowerCase() === 'amoeba' },
    { 'fb-panel--texture-teardrop': texture && texture.toLowerCase() === 'teardrop' },
    { 'fb-panel--texture-tetris': texture && texture.toLowerCase() === 'tetris' },
    { 'fb-panel--texture-teal-x': texture && texture.toLowerCase() === 'teal x' },
    { 'fb-panel--texture-africa': showAfricanTexture },
    { 'fb-panel--texture-africa-nigeria': texture && texture.toLowerCase() === 'nigeria' },
    { 'fb-panel--texture-africa-kenya': texture && texture.toLowerCase() === 'kenya' }
  )

  if (spread || className) {
    return (
      <div className={
        classnames(
          'fb-panel-wrapper',
          {'fb-panel-wrapper--spread': spread},
          className
        )
      }>
        <div className={classes}>
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className={classes}>
      {React.Children.toArray(children).map((child, index) => {
        const isLabel = child.type && child.type.displayName === 'Page.Panel.Label'
        return isLabel ? child : (
          <Section key={`panel-sections${index}`}>
            {child}
          </Section>
        )
      }
      )}
    </div>
  )
}

Panel.propTypes = {
  /**
   * The child elements of the Panel
   */
  children: PropTypes.node.isRequired,

  /**
   * Set background color
   */
  color: PropTypes.oneOf(['white', 'grey', 'brand', 'accent']),

  /**
   * Set if this Panel should have no
   * padding. This is useful when the content has
   * it's own spacing, for example Stats.
   */
  noPadding: PropTypes.bool,

  /**
   * Set if this Panel should have no top padding.
   */
  noPaddingTop: PropTypes.bool,

  /**
   * Set if this Panel should fill the available space (flex)
   */
  spread: PropTypes.bool,

  /**
   * Set a textured background
   */
  texture: PropTypes.oneOf([undefined, '', 'amoeba', 'teardrop', 'tetris', 'africa', 'nigeria', 'kenya']),

  /**
   * Add additional classnames to Panel. Use carefully.
   */
  className: PropTypes.string
}

Panel.defaultProps = {
  color: 'white',
  noPadding: false,
  noPaddingTop: false,
  spread: false,
  texture: '',
  className: undefined
}

Panel.displayName = 'Page.Panel'
Panel.Label = Label
Panel.Section = Section

export default Panel
