import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TextureHeaderChar from './TextureHeaderChar'

class TextureHeading extends React.Component {
  state = {
    texturesLoaded: false,
    loadedChars: [],
    headingChars: this.props.children
      .toUpperCase()
      .replace(/\s/g, '')
      .split('')
      .filter(function (item, i, ar) {
        return ar.indexOf(item) === i
      })
  }

  handleImageLoaded = (char) => {
    let texturesLoaded = this.state.texturesLoaded
    const loadedChars = this.state.loadedChars

    if (texturesLoaded || loadedChars.includes(char)) return

    loadedChars.push(char)
    texturesLoaded = this.state.headingChars.length === loadedChars.length

    this.setState({
      loadedChars,
      texturesLoaded
    })
  }

  render () {
    const {
      children,
      color,
      tag,
      className,
      ...otherProps
    } = this.props

    if (!children) return null

    const { texturesLoaded } = this.state
    const characters = Array.from(children)

    return (
      createElement(
        tag,
        {
          className: classnames(
            'fb-texture-heading',
            {'fb-texture-heading--is-loaded': texturesLoaded},
            {'fb-texture-heading--color-white': color === 'white'},
            className
          ),
          ...otherProps
        },
        characters.map((character, index) => {
          const char = character.toUpperCase()
          const imgSource = `/assets/textures/characters/${color}/${char}.png`

          return (
            <span
              key={`${children}-${index}`}
              className={classnames(
                'fb-texture-character',
                `fb-texture-character--${char}`
              )}
            >
              { char }
              { char !== ' ' &&
                <span className='fb-texture-character__image-wrapper'>
                  <TextureHeaderChar
                    src={imgSource}
                    className='fb-texture-character__image'
                    onLoad={() => this.handleImageLoaded(char)}
                    char={char}
                  />
                </span>
              }
            </span>
          )
        })
      )
    )
  }
}

TextureHeading.propTypes = {
  /**
  * Content for the heading.
  */
  children: PropTypes.string.isRequired,
  /**
   * Optionally set color if heading is used on a colored background
   */
  color: PropTypes.oneOf(['white', 'black']),
  /**
   * HTML tag, e.g. a semantic heading tag representing the level of the heading
   */
  tag: PropTypes.string
}

TextureHeading.defaultProps = {
  children: '',
  color: 'white',
  tag: 'div'
}

TextureHeading.displayName = 'Text.TextureHeading'

export default TextureHeading
