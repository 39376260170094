import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const PanelLabel = ({children, color, ...otherProps}) => {
  const classes = classnames(
    'fb-panel-label',
    { 'fb-panel-label--color-neutral': !color || color.toLowerCase() === 'neutral' },
    { 'fb-panel-label--color-brand': color && color.toLowerCase() === 'brand' },
    { 'fb-panel-label--color-accent': color && color.toLowerCase() === 'accent' }
  )

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  )
}

PanelLabel.propTypes = {
  /**
   * The child elements of the Panel
   */
  children: PropTypes.node.isRequired,

  /**
   * Label color
   */
  color: PropTypes.oneOf(['neutral', 'brand', 'accent'])
}

PanelLabel.defaultProps = {
  color: 'neutral'
}

PanelLabel.displayName = 'Page.Panel.Label'

export default PanelLabel
