import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Footer = ({
  items,
  linkRenderer,
  site,
  color,
  ...otherProps
}) => {
  const productNames = {
    fi: undefined,
    fs: 'Field Supply',
    sl: 'Shelf Life'
  }

  const isProductSite = site === 'fs' || site === 'sl'
  const currentYear = new Date().getFullYear()

  return (
    <footer className={classnames(
      'fb-footer',
      {'fb-footer--color-brand': color === 'brand'}
    )}>
      <div className='fb-footer__content'>

        {(items && items.length > 0) && (
          <div className='fb-footer-navigation'>
            {items.map(({title, path, external, button, ...otherProps}, index) => {
              if (external) {
                return (
                  <a
                    key={`footernavlink${index}`}
                    href={path}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fb-footer-navigation__item'
                    {...otherProps}
                  >
                    {title}
                  </a>
                )
              }

              return linkRenderer(
                {
                  key: `footernavlink${index}`,
                  to: path,
                  className: 'fb-footer-navigation__item',
                  children: title,
                  ...otherProps
                }
              )
            })}
          </div>
        )}

        <div
          className={classnames(
            'fb-footer-info',
            {'fb-footer-info-wrapper': isProductSite}
          )}
        >
          {isProductSite && (
            <div className='fb-footer-info__company-note'>
              {`${productNames[site]} is brought to you by`}
              &nbsp;<a href='//fieldintelligence.co'>Field</a>
            </div>
          )}

          <div className='fb-footer-info__legal'>
            © Field Intelligence {currentYear}
            <div className='fb-footer-navigation'>
              {
                linkRenderer(
                  {
                    key: `footernavlinklegal`,
                    to: '/legal',
                    className: 'fb-footer-navigation__item',
                    children: 'Legal and privacy'
                  }
                )
              }
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  /**
   * Items in the menu
   */
  items: PropTypes.array.isRequired,

  /**
   * Pass to make navigation links use a custom link renderer, .e.g. Link.
   * Must have `to`, `className`, `children`.
   */
  linkRenderer: PropTypes.func,

  /**
   * To inform on the context
   */
  site: PropTypes.string,

  color: PropTypes.oneOf(['white', 'brand'])
}

Footer.defaultProps = {
  linkRenderer: ({ to, className, children, ...otherProps }) => (<a href={to} className={className} {...otherProps}>{children}</a>),
  site: 'fi',
  color: 'brand'
}

export default Footer
