import { createElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Heading = ({
  children,
  size,
  tight,
  weight,
  color,
  tag,
  className,
  ...otherProps
}) => {
  const headingClass = classnames(
    'fb-heading',
    {'fb-heading--size-small': size === 'small'},
    {'fb-heading--size-smallish': size === 'smallish'},
    {'fb-heading--size-medium': size === 'medium'},
    {'fb-heading--size-largish': size === 'largish'},
    {'fb-heading--size-large': size === 'large'},
    {'fb-heading--size-xlarge': size === 'xlarge'},
    {'fb-heading--size-huge': size === 'huge'},
    {'fb-heading--weight-bold': weight === 'bold'},
    {'fb-heading--tight': tight},
    {'fb-heading--color-light': color === 'light'},
    {'fb-heading--color-brand': color === 'brand'},
    className
  )

  return createElement(
    tag,
    {
      className: headingClass,
      ...otherProps
    },
    children
  )
}

Heading.propTypes = {
  /**
  * Content for the heading.
  */
  children: PropTypes.node.isRequired,
  /**
   * The size you want the heading to render at
   */
  size: PropTypes.oneOf(['inherit', 'small', 'smallish', 'medium', 'largish', 'large', 'xlarge', 'huge']),
  /**
   * Should this heading render with a tight line-height?
   * This is recommended in narrow spaces, e.g. narrow columns.
   */
  tight: PropTypes.bool,
  /**
   * Optionally set color if heading is used on a colored background
   */
  color: PropTypes.oneOf(['inherit', 'light', 'brand']),
  /**
   * HTML tag, e.g. a semantic heading tag representing the level of the heading
   */
  tag: PropTypes.string
}

Heading.defaultProps = {
  size: 'inherit',
  tight: false,
  color: 'inherit',
  tag: 'div'
}

Heading.displayName = 'Text.Heading'

export default Heading
