import React from 'react'

const Logo = ({className}) => (
  <svg viewBox='0 0 603 137' className={className}>
    <title>Shelf Life logo</title>
    <g id='Shelf-Life_Logo' stroke='none'>
      <path d='M13.007,105.606 C13.007,117.514 22.637,125.218 38.222,125.218 C52.405,125.218 61.336,118.564 61.336,107.883 C61.336,100.004 56.958,95.45 48.202,93.874 L27.716,89.847 C12.131,86.87 4.427,79.165 4.427,66.733 C4.427,50.449 17.21,40.468 37.346,40.468 C58.184,40.468 70.616,51.149 70.79,67.784 L60.285,67.784 C59.759,55.702 52.23,49.574 37.521,49.574 C23.513,49.574 15.633,55.702 15.633,65.508 C15.633,73.388 20.887,78.116 31.218,80.041 L49.954,83.543 C65.363,86.519 73.068,94.75 73.068,108.409 C73.068,116.287 69.916,122.592 63.436,127.318 C57.133,132.047 48.728,134.499 38.397,134.499 C16.159,134.499 2.501,123.117 2.501,105.606 L13.007,105.606' id='Fill-1' />
      <path d='M87.978,132.747 L87.978,3.871 L99.009,3.871 L99.009,58.854 L100.059,58.854 C105.663,47.473 117.395,40.468 130.877,40.468 C149.964,40.468 162.397,54.126 162.397,76.364 L162.397,132.747 L151.365,132.747 L151.365,77.415 C151.365,59.729 143.661,50.975 128.251,50.975 C110.74,50.975 99.009,64.632 99.009,84.944 L99.009,132.747 L87.978,132.747' id='Fill-2' />
      <polygon id='Fill-3' points='274.896 132.747 285.753 132.747 285.753 3.871 274.896 3.871' />
      <path d='M346.173,51.674 L327.59,51.674 L327.59,132.747 L316.559,132.747 L316.559,51.674 L300.448,51.674 L304.097,42.219 L316.559,42.219 L316.559,28.036 C316.559,11.926 325.313,4.222 340.548,4.222 C344.398,4.222 347.552,4.572 349.827,5.272 L349.827,14.727 C347.201,14.202 344.398,13.852 341.422,13.852 C331.441,13.852 327.59,18.93 327.59,29.611 L327.59,42.219 L349.827,42.219 L346.173,51.674' id='Fill-4' />
      <polygon id='Fill-5' points='387.717 132.747 405.577 132.747 405.577 3.871 387.717 3.871' />
      <path d='M426.872,132.747 L444.732,132.747 L444.732,40.643 L426.872,40.643 L426.872,132.747 Z M426.872,26.81 L444.732,26.81 L444.732,3.871 L426.872,3.871 L426.872,26.81 Z' id='Fill-6' />
      <path d='M508.2,55.702 L492.451,55.702 L492.451,132.747 L474.416,132.747 L474.416,55.702 L457.605,55.702 L463.41,40.643 L474.416,40.643 L474.416,29.962 C474.416,11.751 484.922,2.821 502.957,2.821 C507.685,2.821 511.362,3.171 513.988,4.047 L513.988,18.755 C511.362,18.23 508.736,17.88 505.934,17.88 C496.304,17.88 492.451,22.433 492.451,31.713 L492.451,40.643 L513.988,40.643 L508.2,55.702' id='Fill-7' />
      <path d='M532.594,77.765 L581.098,77.765 L581.098,74.788 C581.098,62.005 571.641,53.25 557.459,53.25 C542.75,53.25 532.594,62.356 532.594,75.664 L532.594,77.765 Z M557.634,38.891 C570.241,38.891 580.396,42.569 587.927,50.098 C595.456,57.628 599.308,67.784 599.308,80.741 C599.308,83.368 599.134,86.695 598.607,90.723 L532.594,90.723 L532.594,95.801 C532.594,110.334 542.75,120.14 557.983,120.14 C570.065,120.14 578.996,114.012 581.798,104.731 L599.659,104.731 C595.981,122.766 579.521,134.499 557.808,134.499 C530.492,134.499 514.032,115.937 514.032,86.52 C514.032,56.577 530.842,38.891 557.634,38.891 Z' id='Fill-8' />
      <path d='M187.638,80.566 L246.298,80.566 L246.298,76.539 C246.298,60.605 234.917,49.923 217.932,49.923 C199.895,49.923 187.638,61.129 187.638,77.765 L187.638,80.566 Z M246.648,51.324 C253.828,58.504 257.33,68.485 257.33,80.917 C257.33,84.244 257.155,87.046 256.805,89.672 L187.638,89.672 L187.638,94.926 C187.638,113.136 199.72,125.044 218.457,125.044 C232.64,125.044 243.322,117.339 246.648,105.957 L257.681,105.957 C255.754,114.537 251.202,121.366 243.847,126.619 C236.668,131.873 227.913,134.499 217.932,134.499 C205.148,134.499 194.993,130.297 187.463,121.717 C179.933,113.136 176.256,101.58 176.256,87.046 C176.256,57.804 192.016,40.468 217.932,40.468 C230.014,40.468 239.47,44.144 246.648,51.324 Z' id='Fill-9' />
    </g>
  </svg>
)

export default Logo
