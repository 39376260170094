import React from 'react'

const Logo = ({className}) => (
  <svg viewBox='0 0 598 211' className={className}>
    <title>Shelf Life Growth x Access logo</title>
    <defs>
      <polygon id='sl-path-1' points='0.06 0.542 70.567 0.542 70.567 94.573 0.06 94.573' />
      <polygon id='sl-path-2' points='0.477 0.895 512.157 0.895 512.157 210.94 0.477 210.94' />
    </defs>
    <g id='Shelf-Life_Logo_Lockup_RGB_On-Purple' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group-3' transform='translate(0.000000, 38.000000)'>
        <mask id='sl-mask-2' fill='white'>
          <use xlinkHref='#sl-path-1' />
        </mask>
        <path d='M10.506,65.68 C10.506,77.588 20.136,85.292 35.72,85.292 C49.903,85.292 58.835,78.638 58.835,67.957 C58.835,60.078 54.457,55.524 45.701,53.948 L25.214,49.921 C9.63,46.944 1.925,39.239 1.925,26.807 C1.925,10.523 14.709,0.542 34.845,0.542 C55.683,0.542 68.114,11.223 68.289,27.858 L57.783,27.858 C57.258,15.776 49.729,9.648 35.02,9.648 C21.012,9.648 13.132,15.776 13.132,25.582 C13.132,33.462 18.386,38.19 28.716,40.115 L47.453,43.617 C62.862,46.593 70.567,54.824 70.567,68.483 C70.567,76.361 67.415,82.666 60.935,87.392 C54.632,92.121 46.227,94.573 35.896,94.573 C13.658,94.573 0,83.191 0,65.68 L10.506,65.68' id='Fill-1' fill='#FFFFFF' mask='url(#sl-mask-2)' />
      </g>
      <path d='M85.477,130.821 L85.477,1.945 L96.508,1.945 L96.508,56.928 L97.558,56.928 C103.162,45.547 114.894,38.542 128.376,38.542 C147.462,38.542 159.896,52.2 159.896,74.438 L159.896,130.821 L148.864,130.821 L148.864,75.489 C148.864,57.803 141.159,49.049 125.75,49.049 C108.239,49.049 96.508,62.706 96.508,83.018 L96.508,130.821 L85.477,130.821' id='Fill-4' fill='#FFFFFF' />
      <polygon id='Fill-5' fill='#FFFFFF' points='272.395 130.821 283.252 130.821 283.252 1.945 272.395 1.945' />
      <path d='M343.671,49.748 L325.088,49.748 L325.088,130.821 L314.057,130.821 L314.057,49.748 L297.947,49.748 L301.595,40.293 L314.057,40.293 L314.057,26.11 C314.057,10 322.812,2.296 338.046,2.296 C341.897,2.296 345.05,2.646 347.326,3.346 L347.326,12.801 C344.7,12.276 341.897,11.926 338.921,11.926 C328.94,11.926 325.088,17.004 325.088,27.685 L325.088,40.293 L347.326,40.293 L343.671,49.748' id='Fill-6' fill='#FFFFFF' />
      <polygon id='Fill-7' fill='#FFFFFF' points='385.215 130.821 403.076 130.821 403.076 1.945 385.215 1.945' />
      <path d='M424.371,130.821 L442.231,130.821 L442.231,38.717 L424.371,38.717 L424.371,130.821 Z M424.371,24.884 L442.231,24.884 L442.231,1.945 L424.371,1.945 L424.371,24.884 Z' id='Fill-8' fill='#FFFFFF' />
      <g id='Group-14' transform='translate(85.000000, 0.000000)'>
        <mask id='sl-mask-4' fill='white'>
          <use xlinkHref='#sl-path-2' />
        </mask>
        <g id='Clip-10' />
        <path d='M420.699,53.776 L404.95,53.776 L404.95,130.821 L386.915,130.821 L386.915,53.776 L370.104,53.776 L375.909,38.717 L386.915,38.717 L386.915,28.036 C386.915,9.825 397.421,0.895 415.456,0.895 C420.184,0.895 423.861,1.245 426.487,2.121 L426.487,16.829 C423.861,16.304 421.235,15.954 418.432,15.954 C408.802,15.954 404.95,20.507 404.95,29.787 L404.95,38.717 L426.487,38.717 L420.699,53.776' id='Fill-9' fill='#FFFFFF' mask='url(#sl-mask-4)' />
        <path d='M445.092,75.839 L493.596,75.839 L493.596,72.862 C493.596,60.079 484.14,51.324 469.958,51.324 C455.249,51.324 445.092,60.43 445.092,73.738 L445.092,75.839 Z M470.132,36.965 C482.74,36.965 492.895,40.643 500.425,48.172 C507.955,55.702 511.807,65.858 511.807,78.815 C511.807,81.442 511.632,84.769 511.106,88.797 L445.092,88.797 L445.092,93.875 C445.092,108.408 455.249,118.214 470.482,118.214 C482.564,118.214 491.495,112.086 494.296,102.805 L512.158,102.805 C508.48,120.84 492.02,132.573 470.307,132.573 C442.991,132.573 426.531,114.011 426.531,84.594 C426.531,54.651 443.34,36.965 470.132,36.965 Z' id='Fill-11' fill='#FFFFFF' mask='url(#sl-mask-4)' />
        <path d='M100.137,78.64 L158.797,78.64 L158.797,74.613 C158.797,58.679 147.416,47.997 130.43,47.997 C112.394,47.997 100.137,59.203 100.137,75.839 L100.137,78.64 Z M159.147,49.398 C166.327,56.578 169.829,66.559 169.829,78.991 C169.829,82.318 169.654,85.12 169.304,87.746 L100.137,87.746 L100.137,93 C100.137,111.21 112.219,123.118 130.956,123.118 C145.139,123.118 155.82,115.413 159.147,104.031 L170.179,104.031 C168.253,112.611 163.7,119.44 156.345,124.693 C149.167,129.947 140.411,132.573 130.43,132.573 C117.647,132.573 107.491,128.371 99.962,119.791 C92.432,111.21 88.754,99.654 88.754,85.12 C88.754,55.878 104.515,38.542 130.43,38.542 C142.512,38.542 151.968,42.218 159.147,49.398 Z' id='Fill-12' fill='#FFFFFF' mask='url(#sl-mask-4)' />
        <path d='M6.473,182.071 C6.473,185.125 8.916,187.179 12.692,187.179 C16.413,187.179 18.911,185.125 18.911,182.071 L18.911,180.073 C18.911,177.019 16.413,174.91 12.692,174.91 C8.972,174.91 6.473,176.963 6.473,180.073 L6.473,182.071 Z M22.075,202.782 C22.075,200.505 20.577,199.339 17.634,199.339 L9.749,199.339 C6.585,199.339 4.974,200.617 4.974,203.115 C4.974,205.948 7.694,207.39 13.192,207.39 C19.133,207.39 22.075,205.836 22.075,202.782 Z M18.022,195.287 C23.576,195.287 26.851,198.063 26.851,202.56 C26.851,208.113 21.631,211 12.915,211 C4.53,211 0.477,208.335 0.477,204.059 C0.477,201.117 1.976,199.172 4.919,198.229 L4.919,197.84 C3.197,197.229 2.087,195.674 2.087,193.899 C2.087,191.677 3.642,189.789 5.919,189.124 L5.919,188.791 C3.086,187.069 1.698,184.46 1.698,181.016 C1.698,178.184 2.698,175.852 4.697,174.076 C6.751,172.298 9.361,171.411 12.525,171.411 C14.525,171.411 16.246,171.744 17.745,172.354 L17.745,171.411 C17.745,169.079 19.078,167.913 21.742,167.913 L26.462,167.913 L26.462,171.854 L20.965,171.854 L20.965,174.41 C22.742,176.185 23.63,178.463 23.63,181.183 C23.63,184.015 22.631,186.292 20.633,188.013 C18.689,189.734 16.135,190.623 13.025,190.623 C11.527,190.623 10.193,190.456 9.028,190.123 C7.417,190.4 6.473,191.51 6.473,192.788 C6.473,194.454 7.751,195.287 10.36,195.287 L18.022,195.287 Z' id='Fill-13' fill='#FFFFFF' mask='url(#sl-mask-4)' />
      </g>
      <path d='M133.546,171.966 L133.546,175.964 L123.94,175.964 L123.94,200.95 L119.11,200.95 L119.11,176.242 C119.11,173.41 120.553,171.966 123.496,171.966 L133.546,171.966' id='Fill-15' fill='#FFFFFF' />
      <path d='M142.614,188.845 C142.614,194.342 146.502,197.506 151.609,197.506 C156.774,197.506 160.605,194.231 160.605,188.845 L160.605,184.347 C160.605,179.017 156.885,175.464 151.609,175.464 C149,175.464 146.835,176.297 145.113,177.963 C143.448,179.573 142.614,181.738 142.614,184.347 L142.614,188.845 Z M141.504,175.464 C144.059,172.744 147.445,171.411 151.609,171.411 C155.774,171.411 159.161,172.744 161.66,175.408 C164.213,178.074 165.491,181.738 165.491,186.459 C165.491,195.62 160.328,201.505 151.609,201.505 C147.279,201.505 143.892,200.172 141.393,197.452 C138.894,194.731 137.673,191.066 137.673,186.459 C137.673,181.794 138.95,178.13 141.504,175.464 Z' id='Fill-16' fill='#FFFFFF' />
      <polyline id='Fill-17' fill='#FFFFFF' points='205.433 171.966 210.486 171.966 202.38 200.95 196.105 200.95 192.384 186.069 190.498 177.851 190.164 177.851 188.387 186.069 184.833 200.95 178.449 200.95 170.286 171.966 175.506 171.966 179.558 187.346 181.446 196.174 181.836 196.174 187.499 171.966 193.329 171.966 199.27 196.064 199.547 196.064 201.436 187.513 205.433 171.966' />
      <path d='M222.546,196.952 L228.654,196.952 L228.654,200.95 L221.991,200.95 C219.159,200.95 217.771,199.618 217.771,196.896 L217.771,162.361 L222.546,162.361 L222.546,171.966 L228.598,171.966 L228.598,175.964 L222.546,175.964 L222.546,196.952' id='Fill-18' fill='#FFFFFF' />
      <path d='M237.546,200.95 L237.546,160.25 L242.375,160.25 L242.375,177.24 L242.709,177.24 C244.486,173.632 248.206,171.411 252.481,171.411 C258.755,171.411 262.531,175.908 262.531,183.071 L262.531,200.95 L257.756,200.95 L257.756,183.57 C257.756,178.184 255.48,175.464 250.87,175.464 C245.763,175.464 242.375,179.462 242.375,185.625 L242.375,200.95 L237.546,200.95' id='Fill-19' fill='#FFFFFF' />
      <path d='M333.278,193.176 C333.278,196.064 335.443,197.619 338.774,197.619 C344.16,197.619 347.602,194.066 347.602,188.735 L347.602,187.18 L339.329,187.18 C335.498,187.18 333.278,188.901 333.278,191.4 L333.278,193.176 Z M338.884,183.682 L347.602,183.682 L347.602,181.461 C347.602,177.297 345.437,175.242 341.05,175.242 C336.775,175.242 334.443,177.297 334.276,180.629 L329.557,180.629 C329.835,174.854 334.276,171.411 341.328,171.411 C345.16,171.411 348.158,172.688 349.658,174.243 C350.379,175.02 350.99,175.909 351.433,176.963 C352.322,179.018 352.378,180.239 352.378,181.572 L352.378,197.396 L356.653,197.396 L356.653,200.95 L351.711,200.95 C349.046,200.95 348.102,199.839 347.936,197.673 L347.88,196.175 L347.546,196.175 C346.269,199.451 342.383,201.505 337.664,201.505 C331.834,201.505 328.336,198.007 328.336,192.622 C328.336,186.902 332.278,183.682 338.884,183.682 Z' id='Fill-20' fill='#FFFFFF' />
      <path d='M387.611,191.067 C386.667,197.507 381.78,201.505 374.729,201.505 C370.454,201.505 367.067,200.172 364.623,197.453 C362.18,194.731 360.959,191.123 360.959,186.513 C360.959,181.795 362.236,178.074 364.79,175.409 C367.344,172.744 370.731,171.411 374.895,171.411 C381.615,171.411 386.501,175.354 387.444,181.406 L382.67,181.406 C381.725,177.463 378.671,175.464 374.672,175.464 C369.509,175.464 365.9,179.184 365.9,184.514 L365.9,188.901 C365.9,194.066 369.399,197.507 374.672,197.507 C378.949,197.507 382.114,195.008 382.837,191.067 L387.611,191.067' id='Fill-21' fill='#FFFFFF' />
      <path d='M420.332,191.067 C419.387,197.507 414.501,201.505 407.449,201.505 C403.174,201.505 399.788,200.172 397.343,197.453 C394.9,194.731 393.679,191.123 393.679,186.513 C393.679,181.795 394.957,178.074 397.509,175.409 C400.065,172.744 403.452,171.411 407.616,171.411 C414.335,171.411 419.22,175.354 420.165,181.406 L415.389,181.406 C414.446,177.463 411.392,175.464 407.393,175.464 C402.23,175.464 398.621,179.184 398.621,184.514 L398.621,188.901 C398.621,194.066 402.12,197.507 407.393,197.507 C411.67,197.507 414.834,195.008 415.556,191.067 L420.332,191.067' id='Fill-22' fill='#FFFFFF' />
      <path d='M431.086,183.904 L447.855,183.904 L447.855,182.795 C447.855,178.297 444.579,175.297 439.748,175.297 C434.639,175.297 431.086,178.463 431.086,183.127 L431.086,183.904 Z M452.63,184.46 C452.63,185.57 452.574,186.569 452.463,187.458 L431.086,187.458 L431.086,189.124 C431.086,194.231 434.585,197.619 439.915,197.619 C443.968,197.619 447.078,195.508 448.022,192.232 L452.796,192.232 C452.186,195.064 450.742,197.286 448.354,199.007 C445.967,200.672 443.134,201.505 439.804,201.505 C431.363,201.505 426.144,195.675 426.144,186.347 C426.144,177.019 431.363,171.411 439.748,171.411 C447.688,171.411 452.63,176.409 452.63,184.46 Z' id='Fill-23' fill='#FFFFFF' />
      <path d='M463.752,192.177 C463.752,195.564 466.528,197.73 470.802,197.73 C474.856,197.73 477.299,195.953 477.299,192.899 C477.299,190.733 476.023,189.401 473.468,188.901 L467.249,187.736 C462.086,186.792 459.531,184.237 459.531,180.073 C459.531,174.687 463.807,171.411 470.47,171.411 C477.355,171.411 481.519,174.91 481.575,180.239 L476.8,180.239 C476.688,176.852 474.579,175.131 470.47,175.131 C466.528,175.131 464.362,176.852 464.362,179.573 C464.362,181.128 465.029,182.238 466.194,182.849 C467.361,183.349 467.86,183.515 469.026,183.737 L474.746,184.793 C479.797,185.681 482.353,188.291 482.353,192.622 C482.353,198.173 477.855,201.505 470.802,201.505 C463.474,201.505 458.976,197.785 458.976,192.177 L463.752,192.177' id='Fill-24' fill='#FFFFFF' />
      <path d='M492.886,192.177 C492.886,195.564 495.662,197.73 499.937,197.73 C503.991,197.73 506.433,195.953 506.433,192.899 C506.433,190.733 505.158,189.401 502.602,188.901 L496.383,187.736 C491.22,186.792 488.666,184.237 488.666,180.073 C488.666,174.687 492.942,171.411 499.605,171.411 C506.49,171.411 510.654,174.91 510.71,180.239 L505.935,180.239 C505.823,176.852 503.713,175.131 499.605,175.131 C495.662,175.131 493.497,176.852 493.497,179.573 C493.497,181.128 494.164,182.238 495.329,182.849 C496.496,183.349 496.995,183.515 498.161,183.737 L503.88,184.793 C508.932,185.681 511.487,188.291 511.487,192.622 C511.487,198.173 506.99,201.505 499.937,201.505 C492.609,201.505 488.111,197.785 488.111,192.177 L492.886,192.177' id='Fill-25' fill='#FFFFFF' />
      <polyline id='Fill-26' fill='#46DCCD' points='310.796 177.28 304.928 171.411 296.042 180.297 287.155 171.411 281.287 177.28 290.173 186.166 281.287 195.052 287.155 200.921 296.042 192.035 304.928 200.921 310.796 195.052 301.911 186.166 310.796 177.28' />
    </g>
  </svg>
)

export default Logo
