import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'

import Mailto from './Mailto'

class Button extends React.Component {
  render () {
    const {
      className,
      type,
      color,
      cta,
      href,
      mailto,
      children,
      title,
      icon,
      iconSide,
      loading,
      loadingLabel,
      disabled,
      shape,
      spread,
      component,
      size,
      link,
      ...otherProps
    } = this.props

    const commonProps = {
      className: classnames(
        'fb-button',
        {'fb-button--color-brand': color === 'brand'},
        {'fb-button--color-accent': color === 'accent'},
        {'fb-button--cta': cta},
        {'fb-button--size-small': size === 'small'},
        className
      ),
      title: title,
      ...otherProps
    }

    let wrapperComponent = component
    let specificProps = {}

    if (href) {
      wrapperComponent = 'a'
      specificProps = {
        href: href
      }
    }

    if (mailto) {
      wrapperComponent = Mailto
      specificProps = {
        email: mailto,
        obfuscate: true
      }
    }

    // if a wrapper hasn't been set yet, we assume it's a regular button
    if (!wrapperComponent) {
      wrapperComponent = 'button'
      specificProps = {
        type: type,
        disabled: disabled
      }
    }

    return link ? <Link {...{
      ...commonProps,
      ...specificProps
    }} to={link} >{children}</Link> : createElement(
      wrapperComponent,
      {
        ...commonProps,
        ...specificProps
      },
      children
    )
  }
}

Button.propTypes = {
  /**
   * Content of button. Can be text or any tag valid by HTML standards.
   */
  children: PropTypes.node,
  /**
   * Pass a link destination to render as a button-looking link.
   * If href is set, type and disabled will not be applied.
   */
  href: PropTypes.string,
  /**
   * Pass a link destination to render as a gatsby link.
   * If href is set, type and disabled will not be applied.
   */
  link: PropTypes.string,
  /**
   * Pass a title to a Button to have a small tooltip rendered on hover.
   * It should be used with an icon-only Button (without text).
   */
  title: PropTypes.string,
  /**
   * Pass an optional alternative top level element to use as the "button", e.g. CrossLink.
   * Can be a Component, stateless function, or string corresponding to a default JSX element.
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /**
   * Use to disable the button (not applied when link)
   */
  disabled: PropTypes.bool,
  /**
   * type attribute, e.g. submit (not applied when link)
   */
  type: PropTypes.string,
  /**
   * Color variant, e.g. based on the role it has in the interface
   */
  color: PropTypes.oneOf(['brand', 'accent']),
  /**
   * Any additional class names you want to add to the button
   */
  className: PropTypes.string,
  /**
   * CTA button
   */
  cta: PropTypes.bool,
  /**
   * The size of the button
   */
  size: PropTypes.oneOf(['default', 'small'])
}

Button.defaultProps = {
  children: null,
  title: null,
  href: null,
  link: null,
  component: undefined,
  disabled: false,
  type: 'button',
  color: 'brand',
  className: undefined,
  cta: false,
  size: 'default'
}

export default Button
